import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

const BlockRightClick = () => {
  useEffect(() => {
    const preventContextMenu = (e) => {
      e.preventDefault();
    };

    // Bloquear o menu de contexto
    window.addEventListener("contextmenu", preventContextMenu);

    // Monitorar se o console do desenvolvedor está aberto
    const devToolsOpen = () => {
      const element = new Image();
      Object.defineProperty(element, "id", {
        get: () => {
          // Aqui você pode implementar o que deseja fazer se o console estiver aberto
          alert("O console do desenvolvedor está aberto!");
          return "devtools-open";
        },
      });
      console.log(element);
    };

    const interval = setInterval(devToolsOpen, 1000);

    return () => {
      window.removeEventListener("contextmenu", preventContextMenu);
      clearInterval(interval);
    };
  }, []);

  return <App />;
};

root.render(
  <React.StrictMode>
    <BlockRightClick />
  </React.StrictMode>
);

reportWebVitals();
