import React, { useEffect, useState } from "react";
import axios from "axios";
import QRCode from "qrcode";
import "tailwindcss/tailwind.css";
import Logo from "./assets/logo.webp";
import Bg01 from "./assets/bg-01.jpg";
import Component01 from "./components/ganhadores/Index";
import RedesBg from "./assets/redes.jpg";
import CapBg from "./assets/capp.jpg";
import { BiLogoInstagramAlt } from "react-icons/bi";
import InputMask from "react-input-mask";
import { FiPhone } from "react-icons/fi"; // Ícone de telefone
import ReactPixel from "react-facebook-pixel";

function DesktopView() {
  // Adicione o Pixel do Facebook ao carregar o componente
  useEffect(() => {
    // Inicializar o Pixel
    const options = {
      autoConfig: true, // Opcional, define configurações automáticas
      debug: false // Opcional, define o modo debug
    };
    ReactPixel.init("1022474222965029", {}, options);

    // Registrar visualização de página
    ReactPixel.pageView();
  }, []);

  const [step1, setStep1] = useState(true);
  const [stepPagar, setStepPagar] = useState(false);

  let valorfinalpagar = null;

  //aqui

  const [isOpen, setIsOpen] = useState(false);

  // Função para alternar o estado de visibilidade
  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const togglePopup2 = () => {
    setIsPopupOpen2(!isPopupOpen2);
    setDataGetw(null);
  };

  const togglePopupPagar = () => {
    setStepPagar(!stepPagar);
    togglePopup2();
  };

  const [quantity, setQuantity] = useState(2);
  const pricePerCota = 0.99; // Valor de cada cota
  const maxQuantity = 1000; // Limite máximo de cotas

  const handleIncrement = () => {
    setQuantity((prev) => (prev < maxQuantity ? prev + 1 : prev));
  };

  const handleDecrement = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const totalPrice = (quantity * pricePerCota).toFixed(2);

  const totalPriceInCents = Math.round(totalPrice * 100); // Valor em centavos

  valorfinalpagar = totalPriceInCents;

  const [phoneNumber, setPhoneNumber] = useState(""); // Estado para armazenar o valor do telefone

  const [dataGetw, setDataGetw] = useState(null);
  const [loading, setLoading] = useState(false); // Estado para o carregamento

  const [checkoutt, setCheckoutt] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [codigoCopiado, setCodigoCopiado] = useState("");

  let copiaeCOlabs = "";

  const handleSelection = (amount) => {
    setStepPagar(true);
    const newCheckoutState = !checkoutt;

    setLoading(true);

    // Multiplica o valor por 100 para adicionar dois zeros à direita
    const formattedAmount = valorfinalpagar;

    const data = JSON.stringify({
      amount: formattedAmount // Passa o valor formatado
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://rghocgzrwi.execute-api.us-east-1.amazonaws.com/dev/2sdfjlj4c22",
      headers: {
        accept: "application/json",
        "content-type": "application/json"
      },
      data: data
    };

    // Fazendo a requisição com Axios
    axios
      .request(config)
      .then(async (response) => {
        copiaeCOlabs = response.data.pix.qrcode;

        setDataGetw(response.data);

        // Enviar evento de Checkout para o Facebook Pixel
        ReactPixel.track("Checkout", {
          value: formattedAmount // Valor do pagamento
        });

        // Obtém o código QR do PIX e gera o QR Code
        if (response.data?.pix?.qrcode) {
          copiaeCOlabs = response.data.pix.qrcode;
          await generateQRCodeBase64();
        } else {
          throw new Error("QR Code não encontrado na resposta da API");
        }
      })
      .catch((error) => {
        console.error("Erro na requisição: ", error);
      });

    setCheckoutt(newCheckoutState);
  };

  // Função para gerar o QR Code
  const generateQRCodeBase64 = async () => {
    try {
      // Gerar QR Code base64
      const qrCodeBase64 = await QRCode.toDataURL(copiaeCOlabs, {
        width: 300, // Ajuste de largura
        margin: 2 // Margem ao redor do QR Code
      });
      setQrCodeData(qrCodeBase64);
      setLoading(false);
    } catch (err) {
      console.error("Erro:", err);
    }
  };

  const handleCopy = () => {
    const qrCodeText = dataGetw.pix.qrcode;

    navigator.clipboard
      .writeText(qrCodeText)
      .then(() => {
        setCodigoCopiado("Código copiado com sucesso!");
      })
      .catch((err) => {
        console.error("Erro ao copiar o código:", err);
        alert("Erro ao copiar o código.");
      });
  };

  return (
    <>
      {step1 && (
        <div className="bg-[#161c24]">
          <div className="relative">
            <div className="p-7 bg-[#000] flex justify-center items-center gap-4">
              <img
                src={Logo}
                className="w-[180px] mb-10 mx-auto sm:mx-0"
                alt="Imagem Base64"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                className="w-8 h-8 text-[#919eab] ml-96 -mt-5 hidden sm:block"
                viewBox="0 0 24 24"
              >
                <circle cx="4" cy="12" r="1" fill="currentColor"></circle>
                <rect
                  width="14"
                  height="2"
                  x="7"
                  y="11"
                  fill="currentColor"
                  rx=".94"
                  ry=".94"
                ></rect>
                <rect
                  width="18"
                  height="2"
                  x="3"
                  y="16"
                  fill="currentColor"
                  rx=".94"
                  ry=".94"
                ></rect>
                <rect
                  width="18"
                  height="2"
                  x="3"
                  y="6"
                  fill="currentColor"
                  rx=".94"
                  ry=".94"
                ></rect>
              </svg>
            </div>
          </div>

          <div className="relative flex flex-col p-2 items-center w-full max-w-[90%] sm:max-w-[70%] md:max-w-[50%] lg:max-w-[40%] mx-auto bg-[#212b36] rounded-t-2xl -mt-10">
            {/* Imagem sobreposta */}
            <div className="   justify-center items-center mt-1">
              <div className="relative  ">
                {/* Imagem */}
                <img
                  src={Bg01}
                  className="w-full rounded-2xl"
                  alt="Imagem Base64"
                />

                {/* Texto Sobreposto */}
                <div className="absolute rounded-2xl bottom-0 left-0 w-full bg-black bg-opacity-45 text-white p-4">
                  <div>
                    <h2 className="text-lg font-bold">
                      EDIÇÃO 31 - 1 PORSCHE GT3 RS OU 3 M!LHÕES NO PIX
                    </h2>
                    <h6 className="text-sm">Participe e concorra!</h6>
                    <span className="text-xs block">
                      <strong>SUSEP: 15414.657550/2024-96</strong>
                    </span>
                    <button className="mt-2 text-[13px] bg-[#ff3030] animate-pulse text-white font-bold py-1 px-3 rounded">
                      Adquira Já!
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center p-3   rounded-lg  ">
                {/* Seção do Sorteio */}
                <div className="flex flex-row">
                  <h6 className="text-sm font-medium text-[#e6e8e9] p-1">
                    Sorteio
                  </h6>
                  <span className="text-sm font-medium ml-5 text-[#e6e8e9] bg-[#151b23] rounded-lg p-1">
                    14/12/2024 às 18:55
                  </span>
                </div>

                {/* Seção do preço */}
                <div className="flex flex-row items-center">
                  <h6 className="text-sm font-medium text-[#e6e8e9]">
                    Por apenas
                  </h6>
                  <span className="text-[20px] font-bold ml-2 text-[#e6e8e9] bg-[#151b23] rounded-lg p-1">
                    R$&nbsp;0,99
                  </span>
                </div>
              </div>

              <div>
                {/* Botão que controla o popup */}
                <button
                  className="bg-[#6c757d]  text-[18px] mt-2 text-white font-medium py-1 px-4 rounded-md flex items-center justify-center w-full hover:bg-[#c72525] transition-colors duration-300"
                  type="button"
                  onClick={togglePopup2}
                >
                  <p className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      className="w-6 h-6 mr-2"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M4 22V6h4q0-1.65 1.175-2.825T12 2t2.825 1.175T16 6h4v16zm2-2h12V8h-2v3h-2V8h-4v3H8V8H6zm4-14h4q0-.825-.587-1.412T12 4t-1.412.588T10 6M6 20V8z"></path>
                    </svg>
                    Ver meus títulos
                  </p>
                </button>

                {/* Popup */}
                {isPopupOpen2 && (
                  <>
                    {!stepPagar ? (
                      <div className="fixed inset-0 flex items-center justify-center bg-[#212b36] bg-opacity-50 z-50">
                        <div className="bg-[#212b36] rounded-lg shadow-lg p-4 relative w-11/12 sm:w-8/12 md:w-6/12">
                          {/* Conteúdo do Popup */}
                          <div className="space-y-4">
                            {/* Ícone e Título */}
                            <div className="text-white relative overflow-y-auto flex w-full rounded-lg p-4">
                              {/* Coluna da esquerda */}
                              <div className="flex items-center justify-center">
                                <h2 className="text-[30px] font-bold">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    className="MuiBox-root css-ci8oll iconify iconify--mdi"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25q0-.075.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
                                    ></path>
                                  </svg>
                                </h2>
                              </div>

                              {/* Coluna da direita */}
                              <div className="flex flex-col justify-center">
                                <h3 className="text-[20px] font-bold ml-5">
                                  Checkout
                                </h3>
                              </div>
                            </div>

                            {/* Botão para fechar o popup */}
                            <button
                              className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center text-white"
                              onClick={togglePopup2}
                              aria-label="Fechar"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
                              </svg>
                            </button>

                            {/* Linha divisória */}
                            <hr className="border-gray-500 opacity-25" />

                            {/* Detalhes do prêmio */}
                            <div className="flex flex-row items-center justify-start">
                              <div
                                className="flex items-center p-4 mb-2 bg-[#04297a] rounded-md shadow-md"
                                role="alert"
                              >
                                <div className="mr-3">
                                  <svg
                                    className="w-7 h-7 text-[#69baf2] "
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                  >
                                    <path d="M12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 C17.5228475,22 22,17.5228475 22,12 C22,9.3478351 20.9464316,6.80429597 19.0710678,4.92893219 C17.195704,3.0535684 14.6521649,2 12,2 Z M13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,11 C11,10.4477153 11.4477153,10 12,10 C12.5522847,10 13,10.4477153 13,11 L13,16 Z M12,9 C11.4477153,9 11,8.55228475 11,8 C11,7.44771525 11.4477153,7 12,7 C12.5522847,7 13,7.44771525 13,8 C13,8.55228475 12.5522847,9 12,9 Z"></path>
                                  </svg>
                                </div>
                                <div className="text-[#cff1fe]">
                                  <p>
                                    Você está adquirindo{" "}
                                    <strong>Cotas de</strong> unidade(s) do
                                    produto{" "}
                                    <strong>
                                      EDIÇÃO 31 - 1 PORSCHE GT3 RS OU 3 M!LHÕES
                                      NO PIX
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* Campo de entrada com ícone de telefone */}
                            <div className="inline-flex items-center border border-[#45505c] relative w-full rounded-md bg-gray-800 text-white p-3">
                              <FiPhone className="text-[20px] mr-3 text-[#cff1fe]" />
                              <InputMask
                                mask="(99) 99999-9999"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className="bg-transparent outline-none w-full text-[20px] text-white font-sans  leading-[1.4375em] font-normal pl-4"
                                placeholder="Digite seu número de telefone"
                              />
                            </div>

                            {/* Detalhes do prêmio */}
                            <div
                              role="alert"
                              class="flex items-center bg-[#7a4f01]    text-yellow-700 p-4 rounded-md"
                            >
                              <div class="mr-3">
                                <svg
                                  className="w-6 h-6 fill-current text-[#f2d360]"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M22.56,16.3 L14.89,3.58 C14.2597186,2.59400001 13.1702353,1.99737652 12,1.99737652 C10.8297647,1.99737652 9.74028139,2.59400001 9.11,3.58 L1.44,16.3 C0.888546003,17.2192471 0.869485343,18.3628867 1.39,19.3 C1.99197363,20.3551378 3.11522982,21.0046397 4.33,21 L19.67,21 C20.8765042,21.0128744 21.9978314,20.3797441 22.61,19.34 C23.146086,18.3926382 23.1269508,17.2292197 22.56,16.3 L22.56,16.3 Z M12,17 C11.4477153,17 11,16.5522847 11,16 C11,15.4477153 11.4477153,15 12,15 C12.5522847,15 13,15.4477153 13,16 C13,16.5522847 12.5522847,17 12,17 Z M13,13 C13,13.5522847 12.5522847,14 12,14 C11.4477153,14 11,13.5522847 11,13 L11,9 C11,8.44771525 11.4477153,8 12,8 C12.5522847,8 13,8.44771525 13,9 L13,13 Z"></path>
                                </svg>
                              </div>

                              <div class="text-[15px] text-yellow-100">
                                Informe seus dados para continuar
                              </div>
                            </div>

                            <div className="text-[#83909c]">
                              <p>
                                Ao efetuar este pagamento e confirmar a compra
                                deste título de capitalização, declaro que li e
                                concordo com os termos disponíveis na página da
                                campanha.
                              </p>
                            </div>

                            <button
                              className="bg-[#c72525]  text-[18px] mt-2 text-white font-medium py-1 px-4 rounded-md flex items-center justify-center w-full hover:bg-[#c72525] transition-colors duration-300"
                              type="button"
                              onClick={handleSelection}
                            >
                              <p className="flex items-center  p-2 text-[20px]">
                                Concluir reserva
                                <svg
                                  className="ml-10"
                                  xmlns="http://www.w3.org/2000/svg"
                                  aria-hidden="true"
                                  role="img"
                                  class="MuiBox-root css-1j59h76 ml-10  iconify iconify--material-symbols"
                                  sx="[object Object]"
                                  width="1em"
                                  height="1em"
                                  preserveAspectRatio="xMidYMid meet"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="m12 16l4-4l-4-4l-1.4 1.4l1.6 1.6H8v2h4.2l-1.6 1.6zm0 6q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                                  ></path>
                                </svg>
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="fixed inset-0 flex items-center justify-center bg-[#212b36] bg-opacity-50 z-50">
                        <div className="bg-[#212b36] rounded-lg shadow-lg p-4 relative w-11/12 sm:w-8/12 md:w-6/12">
                          {/* Conteúdo do Popup */}
                          <div className="space-y-4">
                            <div className="px-10 mt-10 pt-4">
                              <div className="flex flex-col gap-2 w-full justify-center items-center">
                                {/* Botão para fechar o popup */}
                                <button
                                  className="absolute top-2 right-2 w-10 h-10 flex items-center justify-center text-white"
                                  onClick={togglePopupPagar}
                                  aria-label="Fechar"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
                                  </svg>
                                </button>
                                <div className=" "></div>{" "}
                                {qrCodeData && dataGetw ? (
                                  <>
                                    <img
                                      src={qrCodeData}
                                      alt="QR Code"
                                      className="w-64 h-64  "
                                    />

                                    <div className="text-center mb-5">
                                      <p className="text-slate-200 text-sm mt-3">
                                        <strong className="text-[22px] font-extralight">
                                          VALOR:
                                        </strong>{" "}
                                        <p className="text-[32px] mt-2 font-extrabold">
                                          R$ {totalPrice}
                                        </p>
                                      </p>
                                    </div>

                                    <div className="text-center mb-5">
                                      <p className="text-slate-200 text-sm mt-3">
                                        <strong className="text-red-600">
                                          Importante:
                                        </strong>{" "}
                                        Não feche esta página até concluir o
                                        pagamento.
                                      </p>
                                    </div>

                                    {/* Ajuste da altura usando Tailwind */}
                                    <p className="text-slate-200 text-sm font-bold">
                                      Para efetuar o pagamento, leia o código QR
                                      com a câmera do celular ou copie o código
                                      abaixo e selecione a opção "copiar e
                                      colar" no aplicativo do seu banco.
                                    </p>

                                    <input
                                      type="text"
                                      value={dataGetw.pix.qrcode}
                                      disabled
                                      className="text-sm font-semibold border-2 mt-4 border-black/60 py-2 px-2 w-full rounded-md code"
                                    />

                                    {codigoCopiado && (
                                      <p className="text-green-600 my-2 text-center text-sm font-bold">
                                        {codigoCopiado}
                                      </p>
                                    )}

                                    <button
                                      onClick={handleCopy}
                                      className="bg-gradient-to-t from-[#ff3030] to-[#ff3030] text-white text-sm font-bold py-3 rounded-full mt-6 uppercase w-full lg:w-1/2 mx-auto mb-4 hover:bg-blue-600 transition"
                                    >
                                      Copiar código
                                    </button>
                                  </>
                                ) : (
                                  <div className="p-7 flex flex-col justify-center items-center gap-4 ">
                                    <img
                                      src={Logo}
                                      className="w-[180px] mb-2"
                                      alt="Imagem Base64"
                                    />
                                    <span className="text-slate-200 mb-5 text-lg text-center">
                                      Estamos preparando o QR Code. Por favor,
                                      aguarde...
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div>
                {/* Botão que controla o popup */}
                <button
                  className="bg-[#6c757d]  text-[18px] mt-2 text-white font-medium py-1 px-4 rounded-md flex items-center justify-center w-full hover:bg-[#c72525] transition-colors duration-300"
                  type="button"
                  onClick={togglePopup}
                >
                  <p className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      className="w-6 h-6 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5q0 .807-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33 33 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935m10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1q.01.775.056 1.469c.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.5.5 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864c.413-1.12.74-2.64.87-4.667q.045-.694.056-1.469z"
                      ></path>
                    </svg>
                    Prêmios
                  </p>
                </button>

                {/* Popup */}
                {isPopupOpen && (
                  <div className="fixed inset-0 flex items-center justify-center bg-[#212b36] bg-opacity-50 z-50">
                    <div className="bg-[#212b36] rounded-lg shadow-lg p-4 relative w-11/12 sm:w-8/12 md:w-6/12">
                      {/* Conteúdo do Popup */}
                      <div className="space-y-4">
                        {/* Ícone e Título */}
                        <div className="text-white relative overflow-y-auto flex w-full rounded-lg p-4">
                          {/* Coluna da esquerda */}
                          <div className="flex items-center justify-center">
                            <h2 className="text-[50px] font-bold">🏆</h2>
                          </div>

                          {/* Coluna da direita */}
                          <div className="flex flex-col justify-center">
                            <h3 className="text-[30px] font-bold mb-2">
                              Prêmios
                            </h3>
                            <p className="text-[20px]">
                              Esses são os prêmios no sorteio{" "}
                              <strong className="font-bold uppercase">
                                EDIÇÃO 31 - 1 PORSCHE GT3 RS OU 3 MILHÕES NO PIX
                              </strong>
                            </p>
                          </div>
                        </div>

                        {/* Botão para fechar o popup */}
                        <button
                          className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center text-white"
                          onClick={togglePopup}
                          aria-label="Fechar"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
                          </svg>
                        </button>

                        {/* Linha divisória */}
                        <hr className="border-gray-500 opacity-25" />

                        {/* Detalhes do prêmio */}
                        <div className="flex flex-row ml-7 items-center justify-start w-full">
                          <h2 className="text-[50px] text-[#ff3030] font-bold">
                            1º
                          </h2>
                          <p className="text-base ml-4 text-[#ff3030]">
                            UMA PORSCHE GT3 RS OU 3 MILHÕES NO PIX
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                {/* Seção de seleção de títulos */}
                <div
                  className="shadow-lg border-2 border-opacity-20 mt-5 border-[#161c24] rounded-lg p-4"
                  id="detail"
                >
                  <p className="text-center font-normal mb-4 text-[18px] text-[#e6e8e9]">
                    Selecione a quantidade de títulos
                  </p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
                    {[5, 10, 50, 100, 500, 1000].map((amount, index) => (
                      <button
                        key={index}
                        type="button"
                        onClick={() => setQuantity(amount)}
                        className={`w-full ${
                          amount === 10
                            ? "bg-[#ffe3d5] border-2 border-red-500"
                            : "bg-[#f5f5f5]"
                        } hover:bg-[#e0e0e0] p-4 rounded-md mb-4 text-center`}
                        style={
                          amount === 10
                            ? {
                                position: "relative",
                                backgroundColor: "rgb(255, 227, 213)"
                              }
                            : {}
                        }
                      >
                        <h4 className="text-[25px] font-bold">+{amount}</h4>
                        <p className="text-sm text-gray-600">SELECIONAR</p>
                        {amount === 10 && (
                          <span className="absolute top-[1px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-32 bg-red-500 text-white px-2 py-1 rounded-2xl text-xs font-semibold">
                            Mais Popular
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Seção de seleção de quantidade e valor total */}
                <div className="mt-5 rounded-lg grid grid-cols-2 gap-4 w-full">
                  {/* Incremento/Decremento */}
                  <div className="flex items-center border-2 rounded-lg border-[#333d48] justify-between space-x-4">
                    <button
                      className={`p-2 rounded-full ${
                        quantity === 1 ? "cursor-not-allowed" : ""
                      }`}
                      onClick={handleDecrement}
                      disabled={quantity === 1}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        className="w-6 h-6 text-white"
                        viewBox="0 0 24 24"
                      >
                        <g
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M8 12h8"></path>
                        </g>
                      </svg>
                    </button>
                    <input
                      type="text"
                      value={quantity}
                      readOnly
                      className="bg-[#212b36] text-[18px] border-2 rounded-lg border-[#333d48] w-full text-[#fcfcfc] text-center p-2"
                    />
                    <button
                      className="p-2 rounded-full"
                      onClick={handleIncrement}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        className="w-7 h-7 text-white"
                        viewBox="0 0 24 24"
                      >
                        <g
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M8 12h8m-4-4v8"></path>
                        </g>
                      </svg>
                    </button>
                  </div>

                  {/* Botão "Quero participar" */}
                  <div className="flex-shrink-0 flex justify-center items-center">
                    <button
                      onClick={togglePopup2}
                      className="w-full bg-[#ff3030] text-white py-1 px-2 rounded-md hover:bg-[#c72525] flex items-center justify-start"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        className="w-7 h-7 text-white mr-3"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M10.5 15.25A.74.74 0 0 1 10 15l-3-3a.75.75 0 0 1 1-1l2.47 2.47L19 5a.75.75 0 0 1 1 1l-9 9a.74.74 0 0 1-.5.25"
                        ></path>
                        <path
                          fill="currentColor"
                          d="M12 21a9 9 0 0 1-7.87-4.66a8.7 8.7 0 0 1-1.07-3.41a9 9 0 0 1 4.6-8.81a8.7 8.7 0 0 1 3.41-1.07a8.9 8.9 0 0 1 3.55.34a.75.75 0 1 1-.43 1.43a7.6 7.6 0 0 0-3-.28a7.4 7.4 0 0 0-2.84.89a7.5 7.5 0 0 0-2.2 1.84a7.42 7.42 0 0 0-1.64 5.51a7.4 7.4 0 0 0 .89 2.84a7.5 7.5 0 0 0 1.84 2.2a7.42 7.42 0 0 0 5.51 1.64a7.4 7.4 0 0 0 2.84-.89a7.5 7.5 0 0 0 2.2-1.84a7.42 7.42 0 0 0 1.64-5.51a.75.75 0 1 1 1.57-.15a9 9 0 0 1-4.61 8.81A8.7 8.7 0 0 1 12.93 21z"
                        ></path>
                      </svg>
                      <div className="text-start">
                        <h6 className="text-[18px]">Quero participar</h6>
                        <p className="text-[22px] font-bold">R$ {totalPrice}</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <div className=" mt-3 border  rounded-lg border-[#333d48] shadow-lg">
                {/* Botão que controla a visibilidade */}

                <button
                  onClick={toggleContent}
                  className="text-[18px] text-white  p-2 font-medium py-2 px-4 rounded-md flex items-center justify-center w-full hover:text-[#ff3030] hover:bg-[#303840] transition-colors duration-300"
                  type="button"
                >
                  <p className="flex items-center space-x-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      className="w-5 h-5 rotate-180" // Adiciona a classe de rotação
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2M12 7l-5 5h3v4h4v-4h3z"></path>
                    </svg>
                    <span>Descrição/Regulamento</span>
                  </p>
                  <span className="ml-2">{isOpen ? "Fechar" : "Abrir"}</span>
                  <span className="MuiTouchRipple-root css-w0pj6f"></span>
                </button>

                {/* Conteúdo que será mostrado ou ocultado */}
                {isOpen && (
                  <div className="mt-4   text-white p-4 rounded-lg shadow-md">
                    <div className=" text-[18px]">
                      <p class=" ">
                        O WESLEY ALEM&Atilde;O PR&Ecirc;MIOS VI.29 &eacute; um
                        T&iacute;tulo de Capitaliza&ccedil;&atilde;o da
                        Modalidade Filantropia Premi&aacute;vel, de pagamento
                        &uacute;nico, emitido pela VIA
                        CAPITALIZA&Ccedil;&Atilde;O S.A. (VIACAP), Sociedade de
                        Capitaliza&ccedil;&atilde;o inscrita no CNPJ sob o
                        N&uacute;mero 88.076.302/0001-94, com sede na Av. Carlos
                        Gomes, 222, Conj. 901, Bairro Boa Vista, Porto
                        Alegre/RS, CEP 90480-000.&nbsp;
                        <strong>
                          A contrata&ccedil;&atilde;o deste t&iacute;tulo
                          &eacute; apropriada principalmente na hip&oacute;tese
                          de o consumidor estar interessado em contribuir com
                          entidades beneficentes de assist&ecirc;ncia sociais,
                          certificadas nos termos da legisla&ccedil;&atilde;o
                          vigente, e participar de sorteio(s).
                        </strong>
                        &nbsp;Ao adquirir o WESLEY ALEM&Atilde;O PR&Ecirc;MIOS
                        VI.29 voc&ecirc; concorrer&aacute; aos sorteios
                        previstos neste T&iacute;tulo e 100% do direito de
                        resgate do seu T&iacute;tulo ser&aacute; revertido ao
                        INSTITUTO PLURAL, Entidade Filantr&oacute;pica inscrita
                        no CNPJ sob o N&uacute;mero 12.671.638/0001-33, com sede
                        na Avenida 15 de Novembro, 62, Centro, Corn&eacute;lio
                        Proc&oacute;pio/PR, CEP 86.300-000, para
                        utiliza&ccedil;&atilde;o dos recursos em seus projetos
                        de assist&ecirc;ncia social e nas diversas atividades da
                        entidade, caso n&atilde;o haja comunica&ccedil;&atilde;o
                        contr&aacute;ria &agrave; Sociedade de
                        Capitaliza&ccedil;&atilde;o. Conhe&ccedil;a mais sobre o
                        trabalho e os projetos do INSTITUTO PLURAL
                        atrav&eacute;s do site www.institutoplural.org.br.
                        &Eacute; obrigat&oacute;rio o preenchimento correto da
                        Ficha de Cadastro no momento de aquisi&ccedil;&atilde;o
                        do t&iacute;tulo. Antes de contratar, consulte
                        previamente as Condi&ccedil;&otilde;es Gerais do
                        T&iacute;tulo, cuja vers&atilde;o completa est&aacute;
                        dispon&iacute;vel para consulta no site
                        www.susep.gov.br. Este T&iacute;tulo de
                        Capitaliza&ccedil;&atilde;o foi aprovado pela
                        Superintend&ecirc;ncia de Seguros Privados &ndash; SUSEP
                        &ndash; atrav&eacute;s do Processo SUSEP
                        n&ordm;&nbsp;15414.657550/2024-96.&nbsp;
                        <strong>1 &ndash; SORTEIOS:&nbsp;</strong>Ao adquirir o
                        WESLEY ALEM&Atilde;O PR&Ecirc;MIOS VI.29 o Subscritor
                        ir&aacute; concorrer aos sorteios das modalidades: 1.1)
                        Loteria Federal: Voc&ecirc; concorre em 1 sorteio
                        apurado com base na extra&ccedil;&atilde;o da Loteria
                        Federal do dia 07/12/2024. Para concorrer aos sorteios
                        previstos nesta modalidade o T&iacute;tulo cont&eacute;m
                        impresso 1 N&uacute;mero(s) da Sorte, n&atilde;o
                        repetido(s) na mesma s&eacute;rie, compreendido(s) no
                        intervalo entre &ldquo;0.000.000&rdquo; e
                        &ldquo;9.999.999". Para identificar o t&iacute;tulo
                        sorteado, ao menos um N&uacute;mero da Sorte impresso no
                        t&iacute;tulo dever&aacute; coincidir com o
                        n&uacute;mero formado pela combina&ccedil;&atilde;o do
                        primeiro pr&ecirc;mio da extra&ccedil;&atilde;o da
                        Loteria Federal adicionando-se ao seu final o primeiro
                        algarismo do segundo pr&ecirc;mio da Loteria Federal.
                        Regra de Aproxima&ccedil;&atilde;o: Caso a
                        combina&ccedil;&atilde;o contemplada, apurada pela forma
                        descrita, n&atilde;o tenha sido distribu&iacute;da a um
                        participante ser&aacute; aplicada a seguinte regra de
                        aproxima&ccedil;&atilde;o: ser&aacute; contemplado o
                        participante que possuir o pr&oacute;ximo N&uacute;mero
                        da Sorte imediatamente superior ao efetivamente apurado,
                        na forma acima, e assim sucessivamente at&eacute; que
                        seja identificado um participante contemplado em cada
                        uma das premia&ccedil;&otilde;es da modalidade "Loteria
                        Federal"; com o n&uacute;mero formado pela
                        combina&ccedil;&atilde;o do primeiro pr&ecirc;mio da
                        extra&ccedil;&atilde;o da Loteria Federal,
                        adicionando-se ao seu final o primeiro e o segundo
                        algarismo do segundo pr&ecirc;mio da Loteria
                        Federal.1.2) Modalidade Instant&acirc;nea: No momento da
                        aquisi&ccedil;&atilde;o do t&iacute;tulo, o titular
                        concorrer&aacute; &agrave; premia&ccedil;&atilde;o
                        instant&acirc;nea e identificar&aacute; se o
                        t&iacute;tulo foi contemplado caso o seu n&uacute;mero
                        da sorte corresponda a um dos n&uacute;meros indicados
                        no material de divulga&ccedil;&atilde;o. Ser&atilde;o
                        distribu&iacute;dos 53 pr&ecirc;mios nesta modalidade.
                        Caso, por qualquer motivo, a Loteria Federal n&atilde;o
                        venha a realizar a extra&ccedil;&atilde;o na data
                        prevista de sorteio, ser&aacute; considerada, para fins
                        do disposto neste Cap&iacute;tulo, a
                        extra&ccedil;&atilde;o seguinte que vier a ser por ela
                        realizada. Caso a Caixa Econ&ocirc;mica Federal suspenda
                        definitivamente as extra&ccedil;&otilde;es da Loteria
                        Federal do Brasil, ou modifique as referidas
                        extra&ccedil;&otilde;es de forma que n&atilde;o mais
                        coincidam com as regras deste sorteio, ou haja qualquer
                        impedimento &agrave; vincula&ccedil;&atilde;o da Loteria
                        Federal aos sorteios previstos, a VIA
                        Capitaliza&ccedil;&atilde;o S.A, num prazo m&aacute;ximo
                        de trinta dias, promover&aacute; o sorteio n&atilde;o
                        realizado com aparelhos pr&oacute;prios e em local de
                        livre acesso ao p&uacute;blico, sob
                        fiscaliza&ccedil;&atilde;o de auditoria independente e
                        em id&ecirc;nticas condi&ccedil;&otilde;es &agrave;s
                        previstas originalmente no t&iacute;tulo, dando
                        pr&eacute;via e ampla divulga&ccedil;&atilde;o do
                        fato.&nbsp;
                        <strong>
                          Os T&iacute;tulos sorteados em todas as
                          premia&ccedil;&otilde;es descritas anteriormente,
                          exceto os t&iacute;tulos sorteados nos pr&ecirc;mios
                          da modalidade 'Premia&ccedil;&atilde;o
                          Instant&acirc;nea', ser&atilde;o resgatados
                          antecipadamente quando da realiza&ccedil;&atilde;o do
                          respectivo sorteio e n&atilde;o concorrer&atilde;o aos
                          pr&oacute;ximos sorteios. 2 &ndash; S&Eacute;RIES E
                          PROBABILIDADES:
                        </strong>
                        &nbsp;Os T&iacute;tulos s&atilde;o ordenados em
                        s&eacute;ries de 10.000.000 unidades e a probabilidade
                        m&iacute;nima de contempla&ccedil;&atilde;o,
                        considerando todos os T&iacute;tulos da s&eacute;rie,
                        ser&aacute; igual a uma chance em 10.000.000 para cada
                        um dos sorteios previstos neste T&iacute;tulo. O
                        percentual de 44,02200% do valor da
                        contribui&ccedil;&atilde;o &uacute;nica do T&iacute;tulo
                        &eacute; destinado &agrave; capitaliza&ccedil;&atilde;o,
                        que ir&aacute; gerar um capital m&iacute;nimo de resgate
                        igual a 44,16298%, ao fim da vig&ecirc;ncia. O valor de
                        44,01154% da contribui&ccedil;&atilde;o &uacute;nica do
                        T&iacute;tulo &eacute; destinado ao sorteio. Os custos
                        com promo&ccedil;&atilde;o de distribui&ccedil;&atilde;o
                        do T&iacute;tulo est&atilde;o abrangidos pela quota de
                        carregamento, que &eacute; de 11,96646%, e representam
                        1% da contribui&ccedil;&atilde;o &uacute;nica, podendo
                        haver remunera&ccedil;&atilde;o adicional, de acordo com
                        a performance do produto, conforme previsto em contrato
                        de distribui&ccedil;&atilde;o.&nbsp;
                        <strong>3 &ndash; VIG&Ecirc;NCIA:</strong>&nbsp;A
                        vig&ecirc;ncia do T&iacute;tulo &eacute; de 2
                        meses.&nbsp;<strong>4 &ndash; RESGATE:</strong>&nbsp;O
                        valor do resgate estar&aacute; dispon&iacute;vel ao
                        Titular do direito de resgate ap&oacute;s 2 meses de
                        car&ecirc;ncia.&nbsp;
                        <strong>
                          5 &ndash; DATA, HORA E LOCAL DOS SORTEIOS:
                        </strong>
                        &nbsp;Os sorteios ser&atilde;o realizados com base na
                        extra&ccedil;&atilde;o da Loteria Federal do dia
                        07/12/2024, na hora e local divulgados pela Caixa
                        Econ&ocirc;mica Federal.&nbsp;
                        <strong>6 &ndash; PR&Ecirc;MIOS:</strong>&nbsp;Para o
                        recebimento do pr&ecirc;mio, quando contemplado, o
                        Subscritor dever&aacute; apresentar o T&iacute;tulo
                        original contemplado ou o comprovante de
                        aquisi&ccedil;&atilde;o pelo aplicativo/site; RG e CPF
                        v&aacute;lidos; comprovante de resid&ecirc;ncia
                        atualizado e os dados banc&aacute;rios para
                        dep&oacute;sito. O valor dos pr&ecirc;mios descritos
                        &eacute; l&iacute;quido de imposto de renda. A VIACAP
                        pagar&aacute; a premia&ccedil;&atilde;o em at&eacute; 15
                        (quinze) dias corridos, contados da
                        apresenta&ccedil;&atilde;o da documenta&ccedil;&atilde;o
                        exigida.&nbsp;
                        <strong>
                          7 &ndash; DISPOSI&Ccedil;&Otilde;ES GERAIS:
                        </strong>
                        &nbsp;A aquisi&ccedil;&atilde;o implica
                        autom&aacute;tica ades&atilde;o &agrave;s
                        Condi&ccedil;&otilde;es Gerais do T&iacute;tulo, das
                        quais o Subscritor declara ter tomado ci&ecirc;ncia
                        previamente a aquisi&ccedil;&atilde;o. Os direitos e
                        obriga&ccedil;&otilde;es decorrentes deste T&iacute;tulo
                        prescrevem no prazo estabelecido na
                        legisla&ccedil;&atilde;o. Na hip&oacute;tese de extravio
                        do T&iacute;tulo, a responsabilidade ser&aacute;
                        exclusivamente do Subscritor. O foro competente para
                        dirimir eventuais quest&otilde;es oriundas do presente
                        Regulamento ser&aacute; o do domic&iacute;lio do
                        Subscritor. &Eacute; proibida a venda de t&iacute;tulo
                        de capitaliza&ccedil;&atilde;o a menores de dezesseis
                        anos. A aprova&ccedil;&atilde;o deste plano pela SUSEP,
                        n&atilde;o implica, por parte da Autarquia, em incentivo
                        ou recomenda&ccedil;&atilde;o &agrave; sua
                        aquisi&ccedil;&atilde;o, representando, exclusivamente,
                        sua adequa&ccedil;&atilde;o &agrave;s normas em vigor.
                        Os dados pessoais acessados em raz&atilde;o da
                        contrata&ccedil;&atilde;o deste t&iacute;tulo de
                        capitaliza&ccedil;&atilde;o ser&atilde;o tradados nos
                        termos da Lei Geral de Prote&ccedil;&atilde;o de Dados
                        (Lei n&ordm; 13.709/2018). Para mais
                        informa&ccedil;&otilde;es consulte o&nbsp;
                        <strong>SAC: 0800 740 7819</strong>. OUVIDORIA: 0800
                        8741 505.
                      </p>
                      <p class="ql-align-justify">&nbsp;</p>
                      <p class="ql-align-justify">
                        PREMIA&Ccedil;&Atilde;O: (UMA UNIDADE) PORSCHE GT3 RS
                        &ndash; ANO 2024 (SUGEST&Atilde;O DE USO DO PR&Ecirc;MIO
                        L&Iacute;QUIDO: R$3.000.00,00) OU R$3.000.000,00 REAIS
                      </p>
                      <p class="ql-align-justify">&nbsp;</p>
                      <p class="ql-align-justify">
                        INSTANT&Acirc;NEAS: 150 T&Iacute;TULOS PREMIADOS DE
                        R$1.000,00 (4456108, 9912032, 0543292, 8567090, 0494572,
                        3156537, 1011373, 6274952, 2353941, 6267270, 5219078,
                        4526465, 0164542, 1893765, 2150384, 7143263, 7625423,
                        7021947, 1881969, 5470287, 4471719, 2476339, 0375868,
                        6838958, 3499672, 0567489, 1118769, 3450612, 6943234,
                        7383788, 5566752, 3536602, 3845865, 8941049, 4575021,
                        5224889, 0484343, 3460299, 0328119, 1531434, 0816579,
                        5529027, 8705873, 1999567, 1557224, 9044670, 4499605,
                        8794284, 2990681, 1968284, 8041622, 6885056, 8374203,
                        7909296, 1284977, 3717043, 8825671, 4499886, 2069971,
                        2547676, 3625694, 0094233, 4246900, 8124116, 6488153,
                        5463186, 9450717, 5561972, 9788909, 5758397, 1365785,
                        4955578, 7703947, 5949893, 3354990, 9372140, 5678398,
                        7389580, 3645629, 6331842, 9011642, 6779502, 1488149,
                        1898326, 9327740, 7081407, 1618437, 0067098, 9853018,
                        6137105, 0204087, 1962573, 9421444, 3698718, 6447548,
                        4672305, 1578875, 4859746, 1171553, 1951476, 7368288,
                        2575244, 0641553, 9803516, 1449888, 0498550, 2779057,
                        1928480, 2061449, 0756871, 0495917, 9608609, 0921840,
                        4969158, 6108778, 7888711, 4454871, 2259750, 4811061,
                        3333028, 3591740, 1129777, 7956840, 4664966, 0055724,
                        8536668, 9607094, 8877637, 4095979, 8458601, 5773148,
                        7588089, 4009449, 3729071, 6215987, 6923596, 3808256,
                        7999512, 7581879, 7638610, 1982747, 4585467, 6455594,
                        1523032, 1014918, 3696799, 9128875, 3968879, 8190448,
                        1493375)
                      </p>
                      <p class="ql-align-justify">&nbsp;</p>
                      <p class="ql-align-justify">
                        10 T&Iacute;TULOS PREMIADOS DE R$10.000,00 (5764844,
                        2663267, 5698945, 4131261, 8136743, 9645345, 2903370,
                        9809186, 0273738, 5134733)
                      </p>
                      <p class="ql-align-justify">&nbsp;</p>
                      <p class="ql-align-justify">
                        A VIA CAPITALIZA&Ccedil;&Atilde;O S/A,
                        respons&aacute;vel pela emiss&atilde;o e
                        administra&ccedil;&atilde;o do WESLEY ALEM&Atilde;O
                        PR&Ecirc;MIOS VI.29, T&iacute;tulo de
                        Capitaliza&ccedil;&atilde;o da Modalidade Filantropia
                        Premi&aacute;vel, informa aos seus clientes e
                        consumidores em geral que o sorteio inicialmente
                        previsto para o dia 07/12/2024, ser&aacute; realizado
                        com base na extra&ccedil;&atilde;o da Loteria Federal do
                        dia 11/12/2024.
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-4 my-4">
                {/* Promoção Header */}
                <div className="flex flex-rowitems-center gap-x-4">
                  <h6 className="text-[23px] text-[#e6e8e9] font-semibold flex items-center">
                    🎁 Títulos Premiados
                  </h6>
                  <span className="text-[15px] mt-2 text-[#3d4753]  ">
                    instantâneos
                  </span>
                </div>
              </div>

              <div className="flex flex-col ">
                {/* Premiação 1 */}

                <Component01 />
              </div>

              <p className="text-center font-normal mt-10 mb-4 text-[20px] text-[#e6e8e9]">
                Compartilhe:
              </p>
              <div className="flex items-center justify-center  ">
                <img src={RedesBg} alt="Imagem Base64" />
              </div>
            </div>
          </div>

          <div class="bg-[#212b36] p-4 text-white font-light text-sm ">
            <div className="flex items-center justify-center  ">
              <img src={CapBg} alt="Imagem Base64" />
            </div>
          </div>

          <div class="bg-[#161c24] p-4 text-white font-light text-sm ">
            <div className="flex items-center justify-center  ">
              <p className="text-center font-normal mt-10 mb-4 text-[20px] text-[#e6e8e9]">
                acompanhe nossas redes sociais
              </p>
            </div>

            <div className="flex mb-16 items-center justify-center  ">
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BiLogoInstagramAlt className="w-6 h-6 text-pink-600 hover:text-pink-700" />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Index() {
  return (
    <div>
      <DesktopView />
    </div>
  );
}

export default Index;
