import React from "react";

const participants = [
  {
    number: "4456108",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Gabriel"
    }
  },
  {
    number: "9912032",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Gabriel"
    }
  },
  {
    number: "0543292",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Clevson"
    }
  },
  {
    number: "8567090",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Gabriel"
    }
  },
  {
    number: "0494572",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Pedro"
    }
  },
  {
    number: "3156537",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Andréia"
    }
  },
  {
    number: "1011373",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Felipe"
    }
  },
  {
    number: "6274952",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Lucas"
    }
  },
  {
    number: "2353941",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Gisleide"
    }
  },
  {
    number: "6267270",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Ismael"
    }
  },
  {
    number: "5219078",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Fernando"
    }
  },
  {
    number: "4526465",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Guilherme"
    }
  },
  {
    number: "0164542",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Andrei"
    }
  },
  {
    number: "1893765",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Anderson"
    }
  },
  {
    number: "2150384",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Celio"
    }
  },
  {
    number: "7143263",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Manuel"
    }
  },
  {
    number: "7625423",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Alan"
    }
  },
  {
    number: "7021947",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Jefferson"
    }
  },
  {
    number: "1881969",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Alex"
    }
  },
  {
    number: "5470287",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Nicolas"
    }
  },
  {
    number: "4471719",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Igor"
    }
  },
  {
    number: "2476339",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Fernando"
    }
  },
  {
    number: "0375868",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Willian"
    }
  },
  {
    number: "6838958",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Lucas"
    }
  },
  {
    number: "3499672",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Lucas"
    }
  },
  {
    number: "0567489",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Fellipe"
    }
  },
  {
    number: "1118769",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Kauã"
    }
  },
  {
    number: "3450612",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Kenny"
    }
  },
  {
    number: "6943234",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Matheus"
    }
  },
  {
    number: "7383788",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Roberto"
    }
  },
  {
    number: "5566752",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "José"
    }
  },
  {
    number: "3536602",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Éder"
    }
  },
  {
    number: "3845865",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Vinícius"
    }
  },
  {
    number: "8941049",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Jaqueline"
    }
  },
  {
    number: "4575021",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Paulo"
    }
  },
  {
    number: "5224889",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Anderson"
    }
  },
  {
    number: "0484343",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "gustavo"
    }
  },
  {
    number: "3460299",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Michael"
    }
  },
  {
    number: "0328119",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Claudio"
    }
  },
  {
    number: "1531434",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Helton"
    }
  },
  {
    number: "0816579",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Emanoel"
    }
  },
  {
    number: "5529027",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Rogério"
    }
  },
  {
    number: "8705873",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Gabriel"
    }
  },
  {
    number: "1999567",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Abraão"
    }
  },
  {
    number: "1557224",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Maria"
    }
  },
  {
    number: "9044670",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Ludiney"
    }
  },
  {
    number: "4499605",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Gil"
    }
  },
  {
    number: "8794284",
    award: "R$1.000,00",
    awarded: true,
    user: {
      name: "Enrique"
    }
  },
  {
    number: "2990681",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1968284",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "8041622",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "6885056",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "8374203",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7909296",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1284977",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3717043",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "8825671",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4499886",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "2069971",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "2547676",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3625694",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "0094233",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4246900",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "8124116",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "6488153",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "5463186",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9450717",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "5561972",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9788909",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "5758397",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1365785",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4955578",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7703947",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "5949893",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3354990",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9372140",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "5678398",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7389580",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3645629",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "6331842",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9011642",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "6779502",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1488149",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1898326",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9327740",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7081407",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1618437",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "0067098",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9853018",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "6137105",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "0204087",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1962573",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9421444",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3698718",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "6447548",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4672305",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1578875",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4859746",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1171553",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1951476",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7368288",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "2575244",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "0641553",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9803516",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1449888",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "0498550",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "2779057",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1928480",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "2061449",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "0756871",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "0495917",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9608609",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "0921840",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4969158",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "6108778",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7888711",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4454871",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "2259750",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4811061",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3333028",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3591740",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1129777",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7956840",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4664966",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "0055724",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "8536668",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9607094",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "8877637",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4095979",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "8458601",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "5773148",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7588089",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4009449",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3729071",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "6215987",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "6923596",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3808256",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7999512",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7581879",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "7638610",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1982747",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "4585467",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "6455594",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1523032",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1014918",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3696799",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "9128875",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "3968879",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "8190448",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "1493375",
    award: "R$1.000,00",
    awarded: false
  },
  {
    number: "5764844",
    award: "R$10.000,00",
    awarded: true,
    user: {
      name: "Gilberto"
    }
  },
  {
    number: "2663267",
    award: "R$10.000,00",
    awarded: false
  },
  {
    number: "5698945",
    award: "R$10.000,00",
    awarded: false
  },
  {
    number: "4131261",
    award: "R$10.000,00",
    awarded: false
  },
  {
    number: "8136743",
    award: "R$10.000,00",
    awarded: false
  },
  {
    number: "9645345",
    award: "R$10.000,00",
    awarded: false
  },
  {
    number: "2903370",
    award: "R$10.000,00",
    awarded: false
  },
  {
    number: "9809186",
    award: "R$10.000,00",
    awarded: false
  },
  {
    number: "0273738",
    award: "R$10.000,00",
    awarded: false
  },
  {
    number: "5134733",
    award: "R$10.000,00",
    awarded: true,
    user: {
      name: "Magno"
    }
  }
];

const formatNumber = (number) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const ParticipantList = () => {
  return (
    <div className="p-3 shadow-lg ">
      {participants.map((participant, index) => (
        <>
          <hr className="border-t border-dashed border-[#91a0ab] opacity-30 border-[1px] " />
          <div
            key={index}
            className="flex flex-row justify-center items-center p-2 rounded-lg"
          >
            <div className="flex w-full">
              <div className=" ">
                {participant.user && participant.user.name && (
                  <span className="text-lg text-[#ff3030]">
                    🏆 {participant.user.name} 🏆
                  </span>
                )}
                <div className="text-center">
                  <span className="text-[15px] font-bold text-[#8f9ca9]">
                    Prêmio:{" "}
                  </span>
                  <span className="text-[15px] font-semibold text-[#74808c]">
                    {participant.award}
                  </span>
                </div>
              </div>
            </div>
            {participant.user && participant.user.name ? (
              <div className="text-center font-bold text-sm border-2 border-[#ff3030] text-[#ff3030] px-2 rounded-md">
                {formatNumber(participant.number)}
              </div>
            ) : (
              <div className="text-center font-bold text-sm border-2 border-[#434e59] text-[#ffffff] px-2 rounded-md">
                {formatNumber(participant.number)}
              </div>
            )}
          </div>
        </>
      ))}
    </div>
  );
};

export default ParticipantList;
